<template>
  <div
    class="
      d-flex
      align-items-center
      flex-row
      open-source__source
    "
  >
    <div class="open-source__source-tag">{{ $t("global_poweredBy") }}</div>
    <div class="open-source__source-keyword d-flex align-items-center">
      <img
        :src="sourceIcon"
        :alt="metaSource"
        class="open-source__source-img"
      />
      <div class="open-source__source-text px-1">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenSource',
  props: {
    title: {
      type: String,
    },
    sourceIcon: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.open-source {
  &__source {
    &-tag {
      flex: 1;
      font-weight: 200;
      font-size: 0.7rem;
    }
    &-text {
      font-weight: 200;
    }
    &-img {
      height: 20px;
      width: auto;
      filter: opacity(0.3) ;
    }
  }
}
</style>
