<template>
  <div>
    <OpenSource
      :title="metaSource"
      :sourceIcon="require('@/assets/images/Wikidata-logo-en.png')"
    />
    <ul class="metainfo__list" v-if="wikiInformation.length > 0">
      <li
        class="metainfo__list-item pt-3"
        v-for="(point, index) in wikiInformation"
        :key="point.id"
      >
        <div class="d-flex flex-column metainfo__result">
          <a
            :href="point.concepturi"
            target="_blank"
            class="metainfo__result-title d-flex align-items-center justify-content-between py-2"
          >
            {{ point.label }}
            <span
              class="metainfo__result-badge badge badge-dark"
              v-if="index === 0"
              >R</span
            >
          </a>
          <div class="metainfo__result-content d-flex flex-column">
            <a
              v-if="point.imageUrl"
              :href="point.imageUrl"
              target="_blank"
              class="justify-content-center d-flex justify-content-center py-2 metainfo__result-image"
            >
              <img :src="point.imageUrl" :alt="point.label" />
            </a>
            <div class="metainfo__result-detail">
              {{ point.description }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="mt-2" v-else>
      <hr class="ed-hr" />
      <p>
        I can only help with the information with the known Entites
        <br />I cannot answer questions. Please try quest mode.
      </p>
    </div>
  </div>
</template>

<script>
import OpenSource from '../../components/OpenSource.vue';

export default {
  name: 'WikiDataCard',
  data() {
    return {
      metaInfoSource: 'Wikidata',
      wikiObject: {
        search: {
          label: 'Not Found',
          description: 'I got no answer to that.',
        },
      },
      metaSource: 'Wikidata',
    };
  },

  components: {
    OpenSource,
  },

  mounted() {},
  created() {
    this.$highway.singleDispatcher('wikiWordFetch');
  },
  watch: {},
  computed: {
    wikiWord() {
      return this.$store.state.wikiWord;
    },

    wikiInformation() {
      return this.$store.state.wikiInformation
        ? this.$store.state.wikiInformation
        : this.wikiObject;
    },
  },
};
</script>

<style lang="scss">
.metainfo {
  &__list {
    padding-left: 0;
    list-style: none;
    margin-top: 20px;
    scroll-snap-type: y mandatory;

    &-item {
      scroll-snap-align: start;
      border: none;
    }
  }

  &__title {
    &-span {
      font-weight: 600;
    }
  }

  &__result {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1em;
    &-title {
      font-weight: 600;
      font-size: 1.1rem;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);
      transition: color ease-out 200ms;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
        color: rgba(0, 0, 0, 1);
        transition: color ease-in 200ms;
      }
    }

    &-wikiinfo {
      padding-bottom: 0.6em;
      border-bottom: 1px solid black;
      font-weight: 600;
    }

    &-badge {
      background-color: #000;
      font-size: 0.65rem;
    }

    &-image {
      transition: all ease-out 200ms;

      &:hover {
        cursor: pointer;
        transform: scale(0.98, 0.98);
        transition: all ease-in 200ms;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }
  }

  hr {
    margin: 1rem 0rem;
  }

  &__source {
    &-tag {
      flex: 1;
      font-weight: 200;
      font-size: 0.7rem;
    }
    &-img {
      height: 20px;
      width: auto;
    }
  }
}
</style>
